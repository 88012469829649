// src/pages/Home.jsx
import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';

const Home = () => {
  const { authenticated } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (!authenticated) {
      navigate('/login'); // Redireciona para a página de login se não estiver autenticado
    }
  }, [authenticated, navigate]);

  return (
    <div className="bg-dark text-light h-100 w-100 d-flex flex-column justify-content-center">
      {authenticated ? (
        <div className="d-flex justify-content-center">
          <h1>Bem-vindo(a)!</h1>
          {/* Conteúdo da página inicial para usuários autenticados */}
        </div>
      ) : (
        <div>
          {/* Opcional: Exibir um spinner ou mensagem de carregamento enquanto verifica a autenticação */}
        </div>
      )}
    </div>
  );
};

export default Home;
