// src/services/localService.js
import axios from './axiosInstance';

const API_URL = process.env.REACT_APP_API_URL;

const localService = {
  async getAll({ contrato_id }) {
    if (contrato_id) {
      try {
        const token = localStorage.getItem('token');
        const contrato = contrato_id ? contrato_id : '';
        const response = await axios.get(
          `${API_URL}/api/locais/contratos/${contrato}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          },
        );
        return response.data;
      } catch (error) {
        throw new Error(
          `Error ao buscar locais: ${
            error.response?.status || 'Erro desconhecido'
          }: ${error.response?.data?.error || error.message}`,
        );
      }
    }
  },

  async getById(id) {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${API_URL}/api/locais/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return response.data;
    } catch (error) {
      throw new Error(
        `Erro ao buscar local: ${
          error.response?.status || 'Erro desconhecido'
        }: ${error.response?.data?.error || error.message}`,
      );
    }
  },

  async create(localData) {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.post(`${API_URL}/api/locais`, localData, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return response.data;
    } catch (error) {
      throw new Error(
        `Erro ao criar local: ${
          error.response?.status || 'Erro desconhecido'
        }: ${error.response?.data?.error || error.message}`,
      );
    }
  },

  async update(id, localData) {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.put(
        `${API_URL}/api/locais/${id}`,
        localData,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      return response.data;
    } catch (error) {
      throw new Error(
        `Erro ao atualizar local: ${
          error.response?.status || 'Erro desconhecido'
        }: ${error.response?.data?.error || error.message}`,
      );
    }
  },

  async delete(id) {
    try {
      const token = localStorage.getItem('token');
      await axios.delete(`${API_URL}/api/locais/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
    } catch (error) {
      throw new Error(
        `Erro ao excluir local: ${
          error.response?.status || 'Erro desconhecido'
        }: ${error.response?.data?.error || error.message}`,
      );
    }
  },
};

export default localService;
