//TestPage.jsx

import React, { useState } from 'react';
import FixedOption from '../components/FormComponents/FixedOption';
import SearchBar from '../components/FormComponents/SearchBar';
import { Button } from 'react-bootstrap';
const TestPage = () => {
  const [formData, setFormData] = useState({ name: '13C' });
  console.log(JSON.stringify(formData));

  /*const handleChange = (event) => {
    if (event?.preventDefault) event.preventDefault();
    const targetName = event.target.name;
    console.log(formData);
    if (formData?.[targetName] === event.target.value) return;
    return setFormData({
      ...formData,
      [targetName]: event.target.value || null,
    });
  };*/
  const handleForeignChange = (event) => {
    const targetName = event?.name;
    if (formData?.[targetName] === event?.value) return;
    return setFormData({ ...formData, [targetName]: event.value || null });
  };
  const buttonClick = () => {
    alert('abc');
    setFormData({ name: '22C' });
  };

  return (
    <div className="login-container d-flex justify-content-center align-items-center h-100 w-100 bg-dark">
      <FixedOption
        name="name"
        onChange={handleForeignChange}
        key={formData.name}
        optionsList={[
          ['13C', '1C2'],
          ['22C', '3C'],
          ['34C', '14C4242'],
        ]}
        value={formData.name}
        label="name"
      />
      <SearchBar />
      <Button onClick={buttonClick}>Mudar estado</Button>
    </div>
  );
};

export default TestPage;
