import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { Form, Button, Row, Col, Alert } from 'react-bootstrap';
import ToastContext from '../context/ToastContext';

const AuthForm = ({ onSubmit, isLogin }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [nome, setNome] = useState(''); // Apenas para registro
  const [validated, setValidated] = useState(false); // Estado para validação do formulário
  const [errorMessage, setErrorMessage] = useState('');
  const { addToast } = useContext(ToastContext);

  const handleSubmit = async (event) => {
    const form = event.currentTarget;
    event.preventDefault();

    if (form.checkValidity() === false) {
      event.stopPropagation();
      setValidated(true);
      return;
    }

    try {
      await onSubmit(email, password, nome);
    } catch (error) {
      addToast({
        // ID simples (pode não ser único em casos raros)
        title: 'Erro',
        description: error.message || 'Erro ao enviar',
        type: 'danger', // Use 'danger' para erro no Bootstrap
      });
      setErrorMessage(
        error.message || 'Ocorreu um erro ao enviar o formulário.',
      );
    }
  };

  return (
    <Form
      noValidate
      validated={validated}
      onSubmit={handleSubmit}
      className="bg-light pb-3 rounded"
    >
      <Row className="mb-0 pb-3">
        {!isLogin && (
          <Form.Group as={Col} md="2" controlId="validationCustom01">
            <Form.Label>Nome</Form.Label>
            <Form.Control
              required
              type="text"
              placeholder="Nome completo"
              value={nome}
              onChange={(e) => setNome(e.target.value)}
            />
            <Form.Control.Feedback type="invalid">
              Por favor, informe seu nome.
            </Form.Control.Feedback>
          </Form.Group>
        )}
        <Form.Group
          className="pb-2"
          as={Col}
          md="12"
          controlId="validationCustom02"
        >
          <Form.Label>Email</Form.Label>
          <Form.Control
            required
            type="email"
            placeholder="Seu email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <Form.Control.Feedback type="invalid">
            Por favor, informe um email válido.
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} md="12" controlId="validationCustom03">
          <Form.Label>Senha</Form.Label>
          <Form.Control
            required
            type="password"
            placeholder="Sua senha"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <Form.Control.Feedback type="invalid">
            Por favor, informe sua senha.
          </Form.Control.Feedback>
        </Form.Group>
      </Row>
      <Button type="submit">{isLogin ? 'Entrar' : 'Cadastrar'}</Button>
      {errorMessage && (
        <Alert variant="danger" className="mt-3">
          {errorMessage}
        </Alert>
      )}
    </Form>
  );
};

AuthForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isLogin: PropTypes.bool.isRequired,
};

export default AuthForm;
