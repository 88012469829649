import React, { useState, useContext } from 'react';
import ForgotPasswordForm from '../components/ForgotPasswordForm';
import ToastContext from '../context/ToastContext';
import authService from '../services/authService';

const ForgotPassword = () => {
  const { addToast } = useContext(ToastContext);
  const [isLoading, setIsLoading] = useState(false);
  const [emailSent, setEmailSent] = useState(false);

  const handleForgotPassword = async (email) => {
    try {
      setIsLoading(true);
      await authService.forgotPassword(email);
      setEmailSent(true);
      addToast({
        title: 'E-mail enviado!',
        description:
          'Verifique sua caixa de entrada para instruções de recuperação de senha.',
        type: 'success',
      });
    } catch (error) {
      addToast({
        title: 'Erro',
        description:
          error.message || 'Ocorreu um erro ao enviar o e-mail de recuperação.',
        type: 'danger',
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="forgot-password-container d-flex justify-content-center align-items-center h-100 w-100 bg-dark">
      <div className="forgot-password-card bg-light p-4 rounded shadow">
        <h2>Esqueci minha senha</h2>
        {emailSent ? (
          <p>Um e-mail com instruções para redefinir sua senha foi enviado.</p>
        ) : (
          <ForgotPasswordForm
            onSubmit={handleForgotPassword}
            isLoading={isLoading}
          />
        )}
      </div>
    </div>
  );
};

export default ForgotPassword;
