import React from 'react';
import { FormControl, Alert } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { FaSearch } from 'react-icons/fa';

const SearchBar = ({ onSearch, searchTerm }) => {
  const erros = [];
  if (!onSearch) {
    onSearch = () => ({});
    erros.push('Não foi definida a função de mudança');
  }

  const handleChange = (event) => {
    let valor = event.target.value || '';
    valor =
      typeof valor === 'string'
        ? valor.toLowerCase()
        : typeof valor === 'number'
        ? valor.toString()
        : '';

    onSearch(valor);
  };
  return (
    <>
      <div className="d-flex align-items-center border rounded p-1">
        <FormControl
          type="search"
          placeholder="Digite sua pesquisa"
          aria-label="Search"
          className="search-input rounded"
          value={searchTerm}
          onChange={handleChange}
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              handleChange(event);
            }
          }}
        />
        <FaSearch
          className="w-25 bg-transparent text-white"
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              handleChange(event);
            }
          }}
        />
      </div>
      {erros.map((e) => (
        <Alert key="e" variant="danger">
          {e}
        </Alert>
      ))}
    </>
  );
};

SearchBar.propTypes = {
  onSearch: PropTypes.func.isRequired,
  searchTerm: PropTypes.string.isRequired,
};
export default SearchBar;
