import axios from './axiosInstance'; // Certifique-se de que axiosInstance esteja configurado corretamente

const API_URL = process.env.REACT_APP_API_URL;

const contractPermissionService = {
  async getAll({ pessoa_id }) {
    if (pessoa_id) {
      try {
        const token = localStorage.getItem('token');
        const contrato = pessoa_id ? pessoa_id : '';
        const response = await axios.get(
          `${API_URL}/api/contract-permissions/users/${contrato}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          },
        );
        return response.data;
      } catch (error) {
        throw new Error(
          `Error ao buscar Permissões: ${
            error.response?.status || 'Erro desconhecido'
          }: ${error.response?.data?.error || error.message}`,
        );
      }
    }
  },

  async getById(id) {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(
        `${API_URL}/api/contract-permissions/${id}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      return response.data;
    } catch (error) {
      throw new Error(
        `Erro ao buscar permissão de contrato: ${
          error.response?.status || 'Erro desconhecido'
        }: ${error.response?.data?.error || error.message}`,
      );
    }
  },

  async create(contractPermissionData) {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.post(
        `${API_URL}/api/contract-permissions`,
        contractPermissionData,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      return response.data;
    } catch (error) {
      throw new Error(
        `Erro ao criar permissão de contrato: ${
          error.response?.status || 'Erro desconhecido'
        }: ${error.response?.data?.error || error.message}`,
      );
    }
  },

  async update(id, contractPermissionData) {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.put(
        `${API_URL}/api/contract-permissions/${id}`,
        contractPermissionData,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      return response.data;
    } catch (error) {
      throw new Error(
        `Erro ao atualizar permissão de contrato: ${
          error.response?.status || 'Erro desconhecido'
        }: ${error.response?.data?.error || error.message}`,
      );
    }
  },

  async delete(id) {
    try {
      const token = localStorage.getItem('token');
      await axios.delete(`${API_URL}/api/contract-permissions/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
    } catch (error) {
      throw new Error(
        `Erro ao excluir permissão de contrato: ${
          error.response?.status || 'Erro desconhecido'
        }: ${error.response?.data?.error || error.message}`,
      );
    }
  },
};

export default contractPermissionService;
