import React, { useContext } from 'react';
import { Toast as BootstrapToast, ToastContainer } from 'react-bootstrap';
import PropTypes from 'prop-types';
import ToastContext from '../context/ToastContext';

const Toast = ({
  toasts,
  position = 'top-end',
  autoClose = true,
  autoCloseDelay = 5000,
}) => {
  const { clearToast } = useContext(ToastContext);

  const handleClose = (toastId) => {
    clearToast(toastId);
  };

  return (
    <ToastContainer position={position} className="p-3 position-fixed">
      {toasts.slice(-5).map((toast) => (
        <BootstrapToast
          key={toast.id}
          onClose={() => handleClose(toast.id)}
          delay={autoClose ? autoCloseDelay : null}
          autohide={autoClose}
          className={`text-white bg-${toast.type}`}
        >
          <BootstrapToast.Header closeButton={autoClose}>
            <strong className="me-auto">{toast.title}</strong>
          </BootstrapToast.Header>
          <BootstrapToast.Body>{toast.description}</BootstrapToast.Body>
        </BootstrapToast>
      ))}
    </ToastContainer>
  );
};

Toast.propTypes = {
  toasts: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired, // Adicione a prop 'id' aqui
      title: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired, // 'success', 'danger', 'warning', etc.
    }),
  ).isRequired,
  position: PropTypes.string,
  autoClose: PropTypes.bool,
  autoCloseDelay: PropTypes.number,
};

export default Toast;
