// src/services/contratoService.js
import axios from './axiosInstance';

const API_URL = process.env.REACT_APP_API_URL;

const contratoService = {
  async getAll() {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${API_URL}/api/contratos`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return response.data;
    } catch (error) {
      throw new Error(
        `Erro ao buscar contratos: ${
          error.response?.status || 'Erro desconhecido'
        }: ${error.response?.data?.error || error.message}`,
      ); // Mensagem personalizada com status e erro da API (se houver)
    }
  },

  async getById(id) {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${API_URL}/api/contratos/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return response.data;
    } catch (error) {
      throw new Error(
        `Erro ao buscar contrato: ${
          error.response?.status || 'Erro desconhecido'
        }: ${error.response?.data?.error || error.message}`,
      );
    }
  },

  async create(contratoData) {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.post(
        `${API_URL}/api/contratos`,
        contratoData,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      return response.data;
    } catch (error) {
      throw new Error(
        `Erro ao criar contrato: ${
          error.response?.status || 'Erro desconhecido'
        }: ${error.response?.data?.error || error.message}`,
      );
    }
  },

  async update(id, contratoData) {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.put(
        `${API_URL}/api/contratos/${id}`,
        contratoData,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      return response.data;
    } catch (error) {
      throw new Error(
        `Erro ao atualizar contrato: ${
          error.response?.status || 'Erro desconhecido'
        }: ${error.response?.data?.error || error.message}`,
      );
    }
  },

  async delete(id) {
    try {
      const token = localStorage.getItem('token');
      await axios.delete(`${API_URL}/api/contratos/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
    } catch (error) {
      throw new Error(
        `Erro ao excluir contrato: ${
          error.response?.status || 'Erro desconhecido'
        }: ${error.response?.data?.error || error.message}`,
      );
    }
  },
};

export default contratoService;
