import React, { useState, useEffect, useContext } from 'react';
import localService from '../services/localService';
import CrudTabela from '../components/CrudTabela';
import { Form, Spinner } from 'react-bootstrap';
import contratoService from '../services/contratoService';
import useMatchMedia from '../context/useWindowSize';
import ToastContext from '../context/ToastContext';
import Select from 'react-select';

import FUNCS from '../utils';
const isDateTime = FUNCS.isDateTime;

const LocaisUser = () => {
  const isDesktopResolution = useMatchMedia('(min-width:992px)', true);
  const { addToast } = useContext(ToastContext);
  const [dados, setDados] = useState([]);
  const [optionsLists, setOptionsLists] = useState({});
  const [optionsLoading, setOptionsLoading] = useState(true);
  const [dadosLoading, setDadosLoading] = useState(true);
  const [contratoSelecionado, setContratoSelecionado] = useState(null);
  const [recarregarBase, setRecarregarBase] = useState(false);
  const [recarregarOptions, setRecarregarOptions] = useState(true);
  const [tipoLocal] = useState('interno');

  const mainCrudService = localService;

  useEffect(() => {
    const fetchDados = async () => {
      try {
        setDadosLoading(true);
        if (recarregarBase && contratoSelecionado) {
          const newdata = await mainCrudService.getAll({
            contrato_id: contratoSelecionado,
          });
          setDados(newdata);
          setRecarregarBase(false);
        }

        // Extrair as datas das convocações e atualizar o estado diasComConvocacoes
      } catch (error) {
        addToast({
          title: 'Erro',
          description: error.message,
          type: 'danger',
        });
      } finally {
        setDadosLoading(false);
      }
    };
    fetchDados();
  }, [recarregarBase]);

  useEffect(() => {
    const fetchOptions = async () => {
      try {
        const secondaryCrudServices = [];
        const newOptions = {};
        const params = {
          contrato_id: contratoSelecionado,
        };

        if (!contratoSelecionado) {
          secondaryCrudServices.push(['contrato_id', contratoService]);
        }
        setOptionsLoading(true);

        const options = await Promise.all(
          secondaryCrudServices.map(async ([nome, func]) => {
            const response = await func.getAll(params);
            return {
              nome,
              options: response.map((valor) => ({
                value: valor.id,
                name: nome,
                label: Object.entries(valor)
                  .filter(
                    ([key, val]) =>
                      !key.startsWith('created') &&
                      !key.startsWith('updated') &&
                      !isDateTime(val) &&
                      val,
                  )
                  .map(([key, val]) => `${key}: ${val}`)
                  .join(' | '),
              })),
            };
          }),
        );

        const addOptions = options.reduce(
          (acc, { nome, options }) => ({ ...acc, [nome]: options }),
          {},
        );

        for (let key in addOptions) {
          newOptions[key] = addOptions[key];
        }

        setOptionsLists(newOptions);
      } catch (error) {
        addToast({
          title: 'Erro',
          description: error.message,
          type: 'danger',
        });
      } finally {
        setOptionsLoading(false);
        //setRecarregarOptions(false);
      }
    };
    if (recarregarOptions) {
      fetchOptions();
    }
  }, [recarregarOptions]);

  const handleChangeContrato = (selectedOption) => {
    setContratoSelecionado(selectedOption ? selectedOption.value : null);
    setRecarregarBase(true);
    setRecarregarOptions(true);
  };

  const formModel =
    tipoLocal !== 'interno'
      ? [
          ['contrato_id', contratoSelecionado, 'Contrato'],
          ['pais', 'str', 'País'],
          ['estado', 'str', 'Estado'],
          ['cidade', 'str', 'Cidade'],
          ['bairro', 'str', 'Bairro'],
          ['rua', 'str', 'Rua'],
          ['CEP', 'str', 'CEP'],
          ['complemento', 'str', 'Complemento'],
          ['pontoreferencia', 'str', ' Ponto de referência'],
          ['nomelocal', 'str', 'Nome do local'],
          ['nome_empresa', 'str', 'Nome da empresa'],
        ]
      : [
          ['contrato_id', contratoSelecionado, 'Contrato'],
          [
            'complemento',
            'str',
            'Site',
            [
              ['Tubarão'],
              ['Vargem Grande'],
              ['Capanema'],
              ['Timbopeba'],
              ['Itabira'],
              ['Brucutu'],
            ],
          ],
          ['pontoreferencia', 'str', 'Área'],
          ['nomelocal', 'str', 'Nome do local'],
          ['nome_empresa', 'str', 'Nome da empresa'],
        ];

  return (
    <div className={`container-xl align-items-start p-0 h-100 w-100 bg-dark`}>
      <div className="d-flex p-2 mt-1 mx-1 rounded shadow-sm justify-items-stretch bg-white">
        <Form.Group key="contrato_id" className="w-100" controlId="contrato_id">
          <Form.Label>Contratos</Form.Label>
          {optionsLoading ? ( // Exibe um spinner enquanto as opções estão carregando
            <div className="container-fluid justify-content-center bg-white align-items-center w-100">
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Carregando...</span>
              </Spinner>
            </div>
          ) : (
            <Select
              name={'contrato_id'}
              value={optionsLists?.contrato_id?.find(
                (option) => option.value === contratoSelecionado,
              )}
              onChange={handleChangeContrato}
              options={optionsLists?.contrato_id}
              isClearable
            />
          )}
        </Form.Group>
      </div>
      <div
        className={`d-flex p-0 ${
          isDesktopResolution
            ? ' align-items-start justify-content-center '
            : 'flex-column align-items-center justify-content-start '
        } w-100 h-100`}
      >
        <CrudTabela
          podeCadastrar={contratoSelecionado > 0}
          forceCard={true}
          className=""
          isLoading={dadosLoading}
          setIsLoading={setDadosLoading}
          onSetDados={setDados}
          dados={dados} // Filtra as convocações
          optionsLists={{
            contrato_id:
              optionsLists?.contrato_id?.filter(
                (vl) => vl.value === contratoSelecionado,
              ) || null,
          }}
          mainCrudService={localService}
          pluralDado={`Endereços ${contratoSelecionado || ''}`}
          nameDado="endereço"
          formModel={formModel}
        />
      </div>
    </div>
  );
};

export default LocaisUser;
