import React, { useContext, useEffect } from 'react';

import AuthForm from '../components/AuthForm';

import { AuthContext } from '../context/AuthContext';

import { useNavigate } from 'react-router-dom';

import ToastContext from '../context/ToastContext';

const Login = () => {
  const { user, login } = useContext(AuthContext);

  const { addToast } = useContext(ToastContext);

  const navigate = useNavigate();

  useEffect(() => {
    // Efeito colateral para observar mudanças no estado `user`

    if (user) {
      // Verifica se o usuário está definido após o login

      addToast({
        title: 'Bem-vindo',

        description: `Bem-vindo ${user.nome}`,

        type: 'success',
      });

      navigate('/');
    }
  }, [user, addToast, navigate]); // Executar o efeito quando o usuário mudar

  const handleLogin = async (email, password) => {
    try {
      await login(email, password); // Espere a resposta da API
    } catch (error) {
      addToast({
        title: 'Erro',

        description: error.message || 'Erro ao processar a solicitação',

        type: 'danger',
      });
    }
  };

  return (
    <div className="login-container d-flex justify-content-center align-items-center h-100 w-100 bg-dark">
      <div className="login-card bg-light p-3 rounded shadow">
        <h2>Login</h2>

        <AuthForm onSubmit={handleLogin} isLogin />

        <p>
          Esqueceu sua senha? <a href="/forgot-password">Clique aqui</a>
        </p>
      </div>
    </div>
  );
};

export default Login;
