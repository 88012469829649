// src/components/Navbar.jsx
import React, { useContext } from 'react';
import { Navbar, Container, Nav, Button, NavDropdown } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import ToastContext from '../context/ToastContext';

const NavigationBar = () => {
  const { authenticated, user, logout } = useContext(AuthContext);
  const navigate = useNavigate();
  const { addToast } = useContext(ToastContext);
  const handleLogout = () => {
    logout();
    navigate('/login');
    addToast({
      // ID simples (pode não ser único em casos raros)
      title: 'Log-out',
      description: `Log-out bem sucedido.`,
      type: 'info', // Use 'danger' para erro no Bootstrap
    });
  };

  return (
    <Navbar
      bg="primary"
      variant="dark"
      expand="lg"
      className="text-light navbar navbar-custom gradient-orange w-100 pe-5 ps-5"
    >
      <Container fluid>
        <Navbar.Brand as={Link} to="/">
          Inspections - Qualieasy
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          {authenticated && (
            <Nav className="me-auto">
              {/* Itens de menu para usuários autenticados */}
              <Nav.Link as={Link} to="/convocacoes">
                Convocações
              </Nav.Link>
              <Nav.Link as={Link} to="/locais">
                Locais
              </Nav.Link>
              {/* Outros links aqui */}
              {user && user.admin === 'admin' && (
                <NavDropdown title="Admin" id="basic-nav-dropdown">
                  <NavDropdown.Item as={Link} to="/register">
                    Cadastrar Novo Usuário
                  </NavDropdown.Item>

                  <NavDropdown.Item as={Link} to="/contractpermissions">
                    Associar usuários a contratos
                  </NavDropdown.Item>
                  {/* Outros links de admin aqui */}
                </NavDropdown>
              )}
            </Nav>
          )}
          <Nav>
            {!authenticated ? (
              <>
                <Nav.Link as={Link} to="/login">
                  Login
                </Nav.Link>
              </>
            ) : (
              <>
                <Navbar.Text className="me-2">Olá, {user.nome}</Navbar.Text>
                <Button variant="outline-light" onClick={handleLogout}>
                  Logout
                </Button>
              </>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavigationBar;
