import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { AuthProvider } from './context/AuthContext'; // Importe o provedor de contexto
import { ToastProvider } from './context/ToastContext';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ToastProvider>
      <AuthProvider>
        {' '}
        {/* Envolva a aplicação com o AuthProvider */}{' '}
        {/* Adicione o ToastProvider aqui */}
        <App />
      </AuthProvider>
    </ToastProvider>
  </React.StrictMode>,
);
