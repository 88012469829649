import React, { useState, useEffect, useContext } from 'react';
import localService from '../services/localService';
import CrudTabela from '../components/CrudTabela';
import contratoService from '../services/contratoService';
import ToastContext from '../context/ToastContext';

const Locais = () => {
  const [dados, setDados] = useState([]);
  const [optionsLists, setOptionsLists] = useState({});

  const { addToast } = useContext(ToastContext);
  useEffect(() => {
    const secondaryCrudServices = [['contrato_id', contratoService]];
    const mainCrudService = localService;
    const fetchDados = async () => {
      try {
        const data = await mainCrudService.getAll();
        const allOptions = await Promise.all(
          secondaryCrudServices.map(async ([nome, func]) => {
            const response = await func.getAll();
            return [
              nome,
              response.map((valor) => {
                let mergedvalues = '';
                for (let ks in valor) {
                  if (
                    ks.slice(0, 7) === 'created' ||
                    ks.slice(0, 7) === 'updated'
                  )
                    continue;
                  mergedvalues = `${mergedvalues}  |  ${ks} ${valor[ks]}`;
                }
                return {
                  value: valor.id,
                  label: mergedvalues, // You might want to replace with a more descriptive label
                };
              }),
            ];
          }),
        );
        const mergedOptions = allOptions.flat();

        let optionsDict = {};
        for (let i = 0; i < mergedOptions.length; i += 2) {
          optionsDict[mergedOptions[i]] = mergedOptions[i + 1];
        }

        setOptionsLists(optionsDict);
        setDados(data);
      } catch (error) {
        addToast({
          title: 'Erro',
          description: error.message,
          type: 'danger',
        });
      }
    };
    fetchDados();
  }, []);

  return (
    <CrudTabela
      onSetOptionsLists={setOptionsLists}
      onSetDados={setDados}
      dados={dados}
      optionsLists={optionsLists}
      mainCrudService={localService}
      pluralDado="Locais"
      nameDado="local"
      formModel={[
        ['contrato_id', 0, 'Contrato'],
        ['pais', 'str', 'País'],
        ['estado', 'str', 'Estado'],
        ['cidade', 'str', 'Cidade'],
        ['bairro', 'str', 'Bairro'],
        ['rua', 'str', 'Rua'],
        ['CEP', 'str', 'CEP'],
        ['complemento', 'str', 'Complemento'],
        ['pontoreferencia', 'str', ' Ponto de referência'],
        ['nomelocal', 'str', 'Nome do local'],
        ['nome_empresa', 'str', 'Nome da empresa'],
      ]}
    />
  );
};

export default Locais;
