import React, { createContext, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import PropTypes from 'prop-types';

const ToastContext = createContext({
  toasts: [],
  addToast: () => {},
  clearToast: () => {},
});

export const ToastProvider = ({ children }) => {
  const [toasts, setToasts] = useState([]);

  const addToast = (toast) => {
    setToasts([...toasts, { ...toast, id: uuidv4() }]);
  };

  const clearToast = (toastId) => {
    setToasts((prevToasts) => prevToasts.filter((t) => t.id !== toastId));
  };

  return (
    <ToastContext.Provider value={{ toasts, addToast, clearToast }}>
      {children}
    </ToastContext.Provider>
  );
};

ToastProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ToastContext;
