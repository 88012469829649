import React, { useState, useContext } from 'react';
import RegisterForm from '../components/RegisterForm';
import ToastContext from '../context/ToastContext';
import authService from '../services/authService';

const Register = () => {
  const { addToast } = useContext(ToastContext);
  const [isLoading, setIsLoading] = useState(false);

  const handleRegister = async (nome, email) => {
    try {
      setIsLoading(true);
      await authService.register(nome, email); // A API cuidará do envio do e-mail
      addToast({
        title: 'Usuário cadastrado!',
        description:
          'O novo usuário foi cadastrado com sucesso e receberá um e-mail para definir a senha.',
        type: 'success',
      });
    } catch (error) {
      addToast({
        title: 'Erro',
        description: error.message || 'Ocorreu um erro ao cadastrar o usuário.',
        type: 'danger',
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="register-container d-flex justify-content-center align-items-center vh-100 bg-dark">
      <div className="register-card bg-light p-4 rounded shadow">
        <h2>Cadastrar Novo Usuário</h2>
        <RegisterForm onSubmit={handleRegister} isLoading={isLoading} />
      </div>
    </div>
  );
};

export default Register;
