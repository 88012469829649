import axios from './axiosInstance';

const API_URL = process.env.REACT_APP_API_URL; // URL da sua API

const authService = {
  async getAll() {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${API_URL}/auth`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return response.data;
    } catch (error) {
      throw new Error(
        `Erro ao buscar usuarios de contrato: ${
          error.response?.status || 'Erro desconhecido'
        }: ${error.response?.data?.error || error.message}`,
      );
    }
  },

  async login(email, password) {
    try {
      const response = await axios.post(`${API_URL}/auth/login`, {
        email,
        password,
      });
      return response.data;
    } catch (error) {
      throw new Error(
        `Erro ao realizar login: ${
          error.response?.status || 'Erro desconhecido'
        }: ${error.response?.data?.error || error.message}`,
      );
    }
  },

  async checkLogin(storedToken) {
    try {
      const response = await axios.get(`${API_URL}/`, {
        headers: { Authorization: `Bearer ${storedToken}` },
      });
      return response.data;
    } catch (error) {
      throw new Error(
        `Erro ao validar sessão: ${
          error.response?.status || 'Erro desconhecido'
        }: ${error.response?.data?.error || error.message}`,
      );
    }
  },

  async register(nome, email) {
    try {
      const token = localStorage.getItem('token');

      const response = await axios.post(
        `${API_URL}/auth/register`,
        { nome: nome, email: email },
        { headers: { authorization: `Bearer ${token}` } },
      );
      return response.data;
    } catch (error) {
      throw new Error(
        `Erro ao registrar: ${error.response?.status || 'Erro desconhecido'}: ${
          error.response?.data?.error || error.message
        }`,
      );
    }
  },

  async forgotPassword(email) {
    try {
      const response = await axios.post(`${API_URL}/auth/forgot-password`, {
        email,
      });
      return response.data;
    } catch (error) {
      throw new Error(
        `Erro ao redefinir: ${error.response?.status || 'Erro desconhecido'}: ${
          error.response?.data?.error || error.message
        }`,
      );
    }
  },

  async resetPassword(email, token, password) {
    try {
      const response = await axios.post(`${API_URL}/auth/reset-password`, {
        email,
        token,
        password,
      });
      return response.data;
    } catch (error) {
      throw new Error(
        `Erro ao redefinir: ${error.response?.status || 'Erro desconhecido'}: ${
          error.response?.data?.error || error.message
        }`,
      );
    }
  },
};

export default authService;
