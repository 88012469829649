// src/services/axiosInstance.js
import axios from 'axios';
import rateLimit from 'axios-rate-limit';

const http = rateLimit(axios.create(), {
  maxRequests: 1, // Máximo de 1 requisição
  perMilliseconds: 1000, // Por 1000 milissegundos (1 segundo)
});

export default http;
