import React from 'react';
import PropTypes from 'prop-types';
import { Form, Table, Button } from 'react-bootstrap';
import { FaPlus, FaTrash } from 'react-icons/fa';

const InputTabela = ({ columns, value, onChange, name, label, keyp }) => {
  const tableData = value
    ? value
        .split('|')
        .map((row) => row.split(';'))
        .map((cells) =>
          columns.reduce((obj, col, index) => {
            obj[col] = cells[index] || '';
            return obj;
          }, {}),
        )
    : [columns.reduce((obj, col) => ({ ...obj, [col]: '' }), {})]; // Inicializa com uma linha vazia se value for vazio

  const handleAddRow = (e) => {
    e.preventDefault();
    onChange({
      target: {
        name,
        value: formatTableData([
          ...tableData,
          columns.reduce((obj, col) => ({ ...obj, [col]: '' }), {}),
        ]),
      },
    });
  };

  const handleDeleteRow = (rowIndex) => {
    onChange({
      target: {
        name,
        value: formatTableData(
          tableData.filter((_, index) => index !== rowIndex),
        ),
      },
    });
  };

  const handleCellChange = (rowIndex, colIndex, newValue) => {
    const sanitizedValue = newValue.replace(/[;|]/g, '');
    const newTableData = tableData.map((row, index) =>
      index === rowIndex
        ? { ...row, [columns[colIndex]]: sanitizedValue }
        : row,
    );
    onChange({ target: { name, value: formatTableData(newTableData) } });
  };

  const formatTableData = (data = tableData) => {
    return data
      .map((row) => columns.map((col) => row[col]).join(';'))
      .join('|');
  };

  return (
    <div>
      <Form.Label>{label || name}</Form.Label>

      <Table striped bordered hover>
        <thead>
          <tr>
            {columns.map((col) => (
              <th key={keyp + 'act' + col}>{col}</th>
            ))}
            <th>Ações</th>
          </tr>
        </thead>
        <tbody>
          {tableData.map((row, rowIndex) => (
            <tr key={keyp + rowIndex + 'r'}>
              {columns.map((col, colIndex) => (
                <td key={keyp + colIndex + 'r' + rowIndex + 'c'}>
                  <Form.Control
                    type="text"
                    value={row[col]}
                    required
                    onChange={(e) =>
                      handleCellChange(rowIndex, colIndex, e.target.value)
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    Por favor, informe {col}.
                  </Form.Control.Feedback>
                </td>
              ))}
              <td>
                <Button
                  variant="danger"
                  onClick={() => handleDeleteRow(rowIndex)}
                >
                  <FaTrash />
                </Button>
              </td>
            </tr>
          ))}
          <tr>
            <td colSpan={columns.length + 1}>
              <Button
                variant="secondary"
                onClick={handleAddRow}
                className="mb-2"
              >
                <FaPlus /> Adicionar Linha
              </Button>
            </td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
};

InputTabela.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.string).isRequired,
  value: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  keyp: PropTypes.string.isRequired,
};

export default InputTabela;
