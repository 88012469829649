import axios from './axiosInstance';

const API_URL = process.env.REACT_APP_API_URL;

const convocacaoService = {
  async getAll(dtstart, dtend, contrato) {
    try {
      const token = localStorage.getItem('token');
      if (!contrato) return [];
      const response = await axios.get(
        `${API_URL}/api/convocacoes/${dtstart}/${dtend}/${contrato}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      return response.data;
    } catch (error) {
      throw new Error(
        `Erro ao buscar convocações: ${
          error.response?.status || 'Erro desconhecido'
        }: ${error.response?.data?.error || error.message}`,
      );
    }
  },

  async getAllInMonthAndContract(data) {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.post(
        `${API_URL}/api/convocacoes/calendar`,
        data,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      return response.data;
    } catch (error) {
      throw new Error(
        `Erro ao buscar convocações por mês e contrato: ${
          error.response?.status || 'Erro desconhecido'
        }: ${error.response?.data?.error || error.message}`,
      );
    }
  },

  async getById(id) {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${API_URL}/api/convocacoes/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return response.data;
    } catch (error) {
      throw new Error(
        `Erro ao buscar convocação: ${
          error.response?.status || 'Erro desconhecido'
        }: ${error.response?.data?.error || error.message}`,
      );
    }
  },

  async create(convocacaoData) {
    delete convocacaoData.id;
    delete convocacaoData.created_at;
    delete convocacaoData.updated_at;
    delete convocacaoData.updated_by;
    delete convocacaoData.created_by;
    try {
      const token = localStorage.getItem('token');
      const response = await axios.post(
        `${API_URL}/api/convocacoes`,
        convocacaoData,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      return response.data;
    } catch (error) {
      throw new Error(
        `Erro ao criar convocação: ${
          error.response?.status || 'Erro desconhecido'
        }: ${error.response?.data?.error || error.message}`,
      );
    }
  },

  async update(id, convocacaoData) {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.put(
        `${API_URL}/api/convocacoes/${id}`,
        convocacaoData,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      return response.data;
    } catch (error) {
      throw new Error(
        `Erro ao atualizar convocação: ${
          error.response?.status || 'Erro desconhecido'
        }: ${error.response?.data?.error || error.message}`,
      );
    }
  },

  async delete(id) {
    try {
      const token = localStorage.getItem('token');
      await axios.delete(`${API_URL}/api/convocacoes/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
    } catch (error) {
      throw new Error(
        `Erro ao excluir convocação: ${
          error.response?.status || 'Erro desconhecido'
        }: ${error.response?.data?.error || error.message}`,
      );
    }
  },
};

export default convocacaoService;
