function Proper(str) {
  return str.replace(
    /\w\S*/g,
    (text) => text.charAt(0).toUpperCase() + text.substring(1).toLowerCase(),
  );
}
function isDateTime(str) {
  // Try parsing the string as a date
  const date = new Date(str);

  // Check if the parsed date is valid (not NaN)
  // AND if the original string and formatted date string match exactly (avoids edge cases)
  return !isNaN(date.getTime()) && str === date.toISOString();
}

export default { Proper, isDateTime };
