// src/pages/Contratos.jsx
import React, { useState, useContext } from 'react';
import Tabela from '../components/Tabela';
import ConvocacaoCard from '../components/ConvocacaoCard';
import DynamicModal from '../components/DynamicModal';
import ToastContext from '../context/ToastContext';
import PropTypes from 'prop-types';
import useMatchMedia from '../context/useWindowSize';
import { Button } from 'react-bootstrap';
import { FaPlus } from 'react-icons/fa';

const CrudTabela = ({
  onSetDados,
  dados,
  optionsLists,
  mainCrudService,
  pluralDado,
  nameDado,
  formModel,
  isLoading,
  setIsLoading,
  className,
  forceCard,
  podeCadastrar = true,
}) => {
  const { addToast } = useContext(ToastContext);
  const defaultdados = {};
  formModel.map((arr) => (defaultdados[arr[0]] = null));

  const [showModal, setShowModal] = useState(false);
  const [dadoSelecionado, setDadoSelecionado] = useState(defaultdados);
  const [modoEdicao, setModoEdicao] = useState(false);
  const isDesktopResolution = useMatchMedia('(min-width:992px)', true);
  const alterNames = {};
  formModel?.map((vl) => (alterNames[vl[0]] = vl[2] || null));

  const handleNovoDado = () => {
    setDadoSelecionado(defaultdados);
    setModoEdicao(false);
    setShowModal(true);
  };

  const handleEditDado = (dado) => {
    setDadoSelecionado(dado);
    setModoEdicao(true);
    setShowModal(true);
  };
  const handleCopyDado = (dado) => {
    setDadoSelecionado(dado);
    setModoEdicao(false);
    setShowModal(true);
  };

  const handleDeleteDado = async (id) => {
    if (window.confirm(`Tem certeza que deseja cancelar esta convocação?`)) {
      try {
        await mainCrudService.delete(id);

        onSetDados((dados) =>
          dados.map((c) => {
            if (c.id !== id) return c;
            c.status = 'cancelada';
            return c;
          }),
        );

        addToast({
          title: `Convocação Cancelada!`,
          description: `A convocação foi cancelada com sucesso.`,
          type: 'success',
        });
      } catch (error) {
        addToast({
          title: 'Erro',
          description: error.message,
          type: 'danger',
        });
      }
    }
  };
  return (
    <div
      className={`container-fluid p-3 mx-1 mt-1 border rounded h-100 bg-light shadow-sm ${className}`}
    >
      <h3>{pluralDado}</h3>
      {podeCadastrar && (
        <Button variant="primary" onClick={handleNovoDado} className="my-3">
          <FaPlus /> Cadastrar {nameDado}
        </Button>
      )}
      {!podeCadastrar && (
        <h3 className="alert bg-warning">
          Selecione um item acima para cadastrar um/a {nameDado}
        </h3>
      )}
      <>
        {isDesktopResolution && !forceCard && (
          <Tabela
            rawData={dados}
            onEdit={handleEditDado}
            onDelete={handleDeleteDado}
            onCopy={handleCopyDado}
            isLoading={isLoading}
            nameDado={nameDado}
            optionsLists={optionsLists}
            alterNames={alterNames}
          />
        )}
        {(!isDesktopResolution || forceCard) && (
          <ConvocacaoCard
            rawData={dados}
            onEdit={handleEditDado}
            onDelete={handleDeleteDado}
            onCopy={handleCopyDado}
            isLoading={isLoading}
            nameDado={nameDado}
            optionsLists={optionsLists}
            alterNames={alterNames}
          />
        )}
      </>
      <DynamicModal
        key={dadoSelecionado.id + 'dnmodal'}
        keyp={dadoSelecionado.id + 'cdnmodal'}
        setIsLoading={setIsLoading}
        nameDado={nameDado}
        crudService={mainCrudService}
        show={showModal}
        onClose={() => setShowModal(false)}
        onSetDado={onSetDados}
        dadoSelecionado={dadoSelecionado}
        modoEdicao={modoEdicao}
        isLoading={isLoading} // Passar isLoading como prop
        optionsLists={optionsLists}
        formModel={formModel}
        alterNames={alterNames} // Passar setIsLoading como prop
      />
    </div>
  );
};

CrudTabela.propTypes = {
  mainCrudService: PropTypes.object.isRequired,
  nameDado: PropTypes.string.isRequired,
  pluralDado: PropTypes.string.isRequired,
  formModel: PropTypes.array.isRequired,
  onSetDados: PropTypes.func.isRequired,
  dados: PropTypes.array.isRequired,
  optionsLists: PropTypes.object,
  isLoading: PropTypes.bool,
  podeCadastrar: PropTypes.bool,
  className: PropTypes.string,
  forceCard: PropTypes.bool,
  setIsLoading: PropTypes.func,
};

export default CrudTabela;
