import React, { createContext, useState, useEffect } from 'react';
import authService from '../services/authService'; // Certifique-se de ter este serviço implementado
import PropTypes from 'prop-types'; // Importe o PropTypes

const AuthContext = createContext({
  authenticated: false,
  user: null,
  loading: true,
  login: () => {},
  logout: () => {},
});

const AuthProvider = ({ children }) => {
  const [authenticated, setAuthenticated] = useState(false);
  const [user, setUser] = useState(null);

  useEffect(() => {
    const storedToken = localStorage.getItem('token');
    const storedUser = localStorage.getItem('user');
    if (storedToken && storedUser) {
      checkLogins(storedToken);
    }
  }, []);

  const login = async (email, password) => {
    const { user, token } = await authService.login(email, password);
    localStorage.setItem('token', token);
    localStorage.setItem('user', JSON.stringify(user));
    setAuthenticated(true);
    setUser(user);
  };

  const checkLogins = async (storedToken) => {
    try {
      const { user } = await authService.checkLogin(storedToken);
      if (user.nome) setAuthenticated(true);
      if (user.nome) setUser(user);
    } catch {
      false;
    }
  };

  const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    setAuthenticated(false);
    setUser(null);
  };
  // ... (código anterior do AuthContext.jsx)

  // ... (funções login e logout)

  const register = async (nome, email) => {
    const { user, token } = await authService.register(nome, email);
    localStorage.setItem('token', token);
    localStorage.setItem('user', JSON.stringify(user));
    setAuthenticated(true);
    setUser(user);
  };

  const forgotPassword = async (email) => {
    await authService.forgotPassword(email);
  };

  const resetPassword = async (token, password, confirmPassword) => {
    if (password !== confirmPassword) {
      throw new Error('Senhas não conferem');
    }
    const response = await authService.resetPassword(token, password);
    return response.data;
  };

  return (
    <AuthContext.Provider
      value={{
        authenticated,
        user,
        login,
        logout,
        register,
        forgotPassword,
        resetPassword,
        checkLogins,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
export { AuthContext, AuthProvider };
