import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Card, Button, Row, Col, Spinner } from 'react-bootstrap';
import { FaEdit, FaTrash } from 'react-icons/fa';
import { format } from 'date-fns';
import SearchBar from './FormComponents/SearchBar';
import FUNCS from '../utils';
const isDateTime = FUNCS.isDateTime;

const EditCard = ({
  rawData,
  onEdit,
  onDelete,
  alterNames,
  isLoading,
  optionsLists,
}) => {
  const [searchTerm, setSearchTerm] = useState('');

  const filtrardados = (dados, termoBusca) => {
    if (!termoBusca) return dados;

    const termoBuscaLower = termoBusca.toLowerCase();
    return dados.filter((dado) => {
      return Object.values(dado).some((valor) => {
        if (typeof valor === 'string' || typeof valor === 'number') {
          return valor.toString().toLowerCase().includes(termoBuscaLower);
        } else if (valor instanceof Date) {
          return format(valor, 'dd/MM/yyyy').includes(termoBuscaLower);
        }
        return false;
      });
    });
  };

  const filtereddados = filtrardados(rawData, searchTerm);

  const handleSearch = (term) => {
    setSearchTerm(term);
  };

  return (
    <div className="container-fluid h-75 p-4 m-0 rounded shadow bg-dark">
      <SearchBar searchTerm={searchTerm} onSearch={handleSearch} />

      {isLoading ? ( // Exibe um spinner enquanto os dados estão carregando
        <div className="d-flex justify-content-center align-items-center h-100">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Carregando...</span>
          </Spinner>
        </div>
      ) : (
        <Row xs={1} sm={1} md={1} lg={2} className="g-4 m-0 pt-2  h-100">
          {filtereddados &&
            filtereddados.map((dado) => {
              return (
                <Col key={dado.id} className={`m-0 p-0`}>
                  <Card
                    className={`${
                      (dado.status === 'cancelada' && 'bg-black text-white') ||
                      'bg-white'
                    } shadow m-0 p-0`}
                  >
                    <Card.Body className="d-flex flex-column justify-content-between h-100">
                      <Card.Title>
                        #{dado.id} - {dado.status || ''}
                      </Card.Title>
                      <Card.Text>
                        {Object.keys(dado).map((key) => {
                          if (
                            key !== 'id' &&
                            key !== 'created_at' &&
                            key !== 'updated_at' &&
                            key !== 'created_by' &&
                            key !== 'updated_by' &&
                            key.slice(-3) !== '_id'
                          ) {
                            let value = dado[key];
                            if (value && isDateTime(value)) {
                              value = value.slice(0, 10);
                            }

                            return (
                              <div className="m-0 p-0" key={key}>
                                <b>{alterNames[key] || key}:</b> {value || '-'}
                              </div>
                            );
                          } else if (key.slice(-3) === '_id') {
                            const dflt =
                              (optionsLists[key] &&
                                optionsLists[key].find(
                                  (k) => k.value === dado[key],
                                )) ||
                              null;

                            let value = dflt?.label || null;

                            return (
                              <div className="m-0 p-0" key={key}>
                                <b>{alterNames[key] || key}:</b> {value || '-'}
                              </div>
                            );
                          }
                          return null;
                        })}
                      </Card.Text>
                      <div className="d-flex justify-content-end mt-auto">
                        <Button
                          variant="outline-primary"
                          onClick={(e) => {
                            e.preventDefault();
                            onEdit(dado);
                          }}
                          className="me-2"
                        >
                          <FaEdit />
                        </Button>
                        <Button
                          variant="outline-danger"
                          onClick={(e) => {
                            e.preventDefault();
                            onDelete(dado.id);
                          }}
                        >
                          <FaTrash />
                        </Button>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              );
            })}
        </Row>
      )}
    </div>
  );
};

EditCard.propTypes = {
  rawData: PropTypes.arrayOf(PropTypes.object).isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  alterNames: PropTypes.object,
  isLoading: PropTypes.bool,
  optionsLists: PropTypes.object,
};

export default EditCard;
