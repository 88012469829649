import React, { useState, useContext } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import ResetPasswordForm from '../components/ResetPasswordForm';
import ToastContext from '../context/ToastContext';
import authService from '../services/authService';

const ResetPassword = () => {
  const [searchParams] = useSearchParams();
  const token = decodeURIComponent(searchParams.get('token'));
  const email = decodeURIComponent(searchParams.get('email'));
  console.log(token);
  const navigate = useNavigate();
  const { addToast } = useContext(ToastContext);
  const [isLoading, setIsLoading] = useState(false);

  const handleResetPassword = async (password, confirmPassword) => {
    try {
      setIsLoading(true);

      if (password !== confirmPassword) {
        throw new Error('As senhas não coincidem');
      }

      await authService.resetPassword(email, token, password);
      addToast({
        title: 'Senha alterada!',
        description: 'Sua senha foi redefinida com sucesso.',
        type: 'success',
      });
      navigate('/login'); // Redireciona para a página de login após a redefinição
    } catch (error) {
      addToast({
        title: 'Erro',
        description: error.message || 'Ocorreu um erro ao redefinir a senha.',
        type: 'danger',
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="reset-password-container d-flex justify-content-center align-items-center vh-100 bg-dark">
      <div className="reset-password-card bg-light p-4 rounded shadow">
        <h2>Redefinir Senha</h2>
        <ResetPasswordForm
          onSubmit={handleResetPassword}
          isLoading={isLoading}
        />
      </div>
    </div>
  );
};

export default ResetPassword;
