import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Button, Row, Col } from 'react-bootstrap';

const ResetPasswordForm = ({ onSubmit, isLoading }) => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [validated, setValidated] = useState(false);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();

    if (form.checkValidity() === false) {
      event.stopPropagation();
      setValidated(true);
      return;
    }

    onSubmit(password, confirmPassword);
  };

  return (
    <Form noValidate validated={validated} onSubmit={handleSubmit}>
      <Row className="mb-3">
        <Form.Group as={Col} md="12" controlId="validationCustom03">
          <Form.Label>Nova Senha</Form.Label>
          <Form.Control
            required
            type="password"
            placeholder="Sua nova senha"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <Form.Control.Feedback type="invalid">
            Por favor, informe sua nova senha.
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group as={Col} md="12" controlId="validationCustom04">
          <Form.Label>Confirme a Senha</Form.Label>
          <Form.Control
            required
            type="password"
            placeholder="Confirme sua nova senha"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
          <Form.Control.Feedback type="invalid">
            Por favor, confirme sua nova senha.
          </Form.Control.Feedback>
        </Form.Group>
      </Row>
      <Button type="submit" disabled={isLoading}>
        {isLoading ? 'Redefinindo...' : 'Redefinir Senha'}
      </Button>
    </Form>
  );
};

ResetPasswordForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
};

export default ResetPasswordForm;
