import React, { useContext } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import ProtectedRoute from './ProtectedRoute'; // Certifique-se de ter este componente
import './custom-bootstrap.scss';
import Login from './pages/Login';
import Locais from './pages/Locais';
import Register from './pages/Register';
import ContractPermissions from './pages/ContractPermissions';
import Home from './pages/Home';
import Convocacoes from './pages/Convocacoes';
import LocaisUser from './pages/LocaisUser';
import ForgotPassword from './pages/ForgotPassword';
import ResetPassword from './pages/ResetPassword';
import TestPage from './pages/TestPage';
import Contratos from './pages/Contratos';
import NavigationBar from './components/Navigationbar';
import Toast from './components/Toast';
import ToastContext from './context/ToastContext';

function App() {
  const { toasts } = useContext(ToastContext);

  return (
    <BrowserRouter>
      <Toast
        toasts={toasts}
        position="top-right"
        autoClose={true}
        autoCloseDelay={3000}
      />
      <div className="d-flex flex-column justify-content-center align-self-center justify-self-center align-items-center p-0 w-100 bg-dark ">
        <NavigationBar />

        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/test-page" element={<TestPage />} />
          <Route element={<ProtectedRoute allowedRoles={['admin', 'user']} />}>
            <Route path="/convocacoes" element={<Convocacoes />} />
            <Route path="/locais" element={<LocaisUser />} />
            {/* ... outras rotas para admins e users */}
          </Route>
          <Route element={<ProtectedRoute allowedRoles={['admin']} />}>
            <Route path="/locaisadmin" element={<Locais />} />
            <Route path="/contratos" element={<Contratos />} />
            <Route path="/register" element={<Register />} />
            <Route
              path="/contractpermissions"
              element={<ContractPermissions />}
            />
            {/* ... outras rotas apenas para admins */}
          </Route>
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
